<template>
    <div class="app-wrapper">
        <!-- <el-scrollbar> -->
            <page-header></page-header>
            <el-container :key="$route.path">
                <!-- <page-sidebar></page-sidebar> -->
                <el-main class="page-main">
                    <!-- <page-breadcrumb></page-breadcrumb> -->
                    <router-view tag="main" />
                </el-main>
            </el-container>
            <page-footer class="hidden-xs-only"></page-footer>
        <!-- </el-scrollbar> -->
        <el-backtop></el-backtop>
        <player></player>
    </div>
</template>

<script>
import PageHeader from "./components/pageHeader";
// import PageSidebar from "./pageSidebar";
import PageFooter from "./components/pageFooter";
// import PageBreadcrumb from './pageBreadcrumb';
import Player from '@/components/Player';

export default {
    components: { /*PageSidebar,*/ PageHeader, PageFooter/*, PageBreadcrumb*/,Player },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
// .app-wrapper{
//     height: 100%;
//     .el-scrollbar{
//         height: 100%;
//     }
// }
.el-container{
    min-height: calc(100vh - #{$--header-height} - 470px );
    // display: flex;
    background: #f0f2f5;
    // .page-main{
    //     padding: 0;
    //     div[tag="main"]{
    //         background: #ffffff;
    //         padding: 20px;
    //     }
    // }
    
}
</style>